import 'slick-carousel';

jQuery( document ).ready(function($) {
    $('.merch-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 800,
                settings:{
                    slidesToShow: 1
                }
            }
        ]
    });
});